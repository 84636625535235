<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="6">
        <!-- Основная информация -->
        <v-card class="pl-12 pr-12 pt-2 pb-2 mt-2 mb-2">
          <v-card-title>Основная информация</v-card-title>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Полное имя"
                v-model="full_name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Имя на сайте (rus):"
                v-model="ru_name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Имя на сайте (eng):"
                v-model="eng_name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Пол"
                outlined
                v-model="gender"
                :items="genderOpt"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Статус"
                v-model="status"
                outlined
                :items="statusOpt"
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <!-- Фотография -->
        <v-card class="pl-12 pr-12 pt-2 pb-2 mt-2 mb-2" min-height="410">
          <v-card-title>Фото:</v-card-title>
          <v-row>
            <v-col cols="4">
              <v-btn
                color="primary"
                @click="isEditPhoto = true"
                class="mr-4 mb-5"
                ><clipper-upload v-model="imageSrc"
                  >Загрузить</clipper-upload
                ></v-btn
              >
              <v-btn @click="getResult">Применить</v-btn>
            </v-col>
            <v-col cols="7">
              <clipper-basic
                v-if="isEditPhoto"
                class="my-clipper"
                ref="clipper"
                :src="imageSrc"
                preview="my-preview"
                :ratio="260/330"
              >
                <div class="placeholder" slot="placeholder">No image</div>
              </clipper-basic>
              <v-img
                :src="imageSrc"
                v-if="!isEditPhoto"
                width="260"
                height="330"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12">
        <!-- Аудио -->
        <v-card class="pl-12 pr-12 pt-2 pb-2 mt-2 mb-2">
          <v-card-title>Аудиофайл:</v-card-title>
          <v-row>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-btn
                color="primary"
                class="mt-3"
                @click="$refs.uploadAudio.click()"
                >Загрузить</v-btn
              >
              <input
                type="file"
                ref="uploadAudio"
                v-show="false"
                @change="previewThumbnail"
              />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-text-field v-model="audioName"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <audio controls :src="audioSrc"></audio>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <!-- Языковая информация -->
        <v-card class="pl-12 pr-12 pt-2 pb-10 mt-2 mb-2">
          <v-card-title>Языковая информация:</v-card-title>
          <v-row>
            <v-col cols="6" >
              <v-select
                label="Язык ISO 639-2"
                outlined
                :items="LANGUAGE"
                v-model="language"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Диалект"
                outlined
                :items="DIALECT"
                v-model="dialect"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Страна"
                outlined
                :items="COUNTRY"
                v-model="country"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Город"
                v-model="city"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Флаг"
                outlined
                :items="flags"
                v-model="flag"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-img :src="'https://rocky-beyond-96534.herokuapp.com' + flag" width="300"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <!-- Биография -->
        <v-card class="pl-12 pr-12 pt-2 mt-2 mb-2" min-height="440">
          <v-card-title>Биография:</v-card-title>
          <v-row>
            <v-col cols="12" class="mt-n4">
              <v-textarea
                label="Биография (rus)"
                outlined
                v-model="bio_ru"
              ></v-textarea>
            </v-col>
            <v-col cols="12" class="mt-n6">
              <v-textarea
                label="Биография (eng)"
                outlined
                v-model="bio_eng"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <!-- Портфолио -->
        <v-card class="pl-12 pr-12 pt-2 pb-2 mt-2 mb-2">
          <v-card-title>Портфолио:</v-card-title>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Upwork"
                outlined
                v-model="upwork_url"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Fiverr"
                outlined
                v-model="fiverr_url"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Дополнительный ресурс 1"
                v-model="extra_url_1"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Дополнительный ресурс 2"
                v-model="extra_url_2"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <!-- Контакты -->
        <v-card class="pl-12 pr-12 pt-2 pb-2 mt-2 mb-2">

          <v-card-title>Контакты:</v-card-title>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="E-mail"
                v-model="email"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Skype"
                outlined
                v-model="skype"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Телефон"
                outlined
                type="number"
                v-model="phone"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Прочие контакты"
                outlined
                v-model="extra_contact"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <!-- Оплата -->
        <v-card class="pl-12 pr-12 pt-2 pb-2 mt-2 mb-2">
          <v-card-title>Оплата:</v-card-title>
          <v-row>
            <v-col cols="6">
              <v-select label="Валюта" outlined v-model="currency" :items="CURRENCY"></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Ставка" outlined v-model="price"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Почта PayPal" outlined v-model="paypal_email"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Прочее (оплата)" outlined v-model="extra_price"></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <!-- Заметки -->
        <v-card class="pl-12 pr-12 pt-2 pb-2 mt-2 mb-2" min-height="300">
          <v-card-title>Заметки:</v-card-title>
          <v-row>
            <v-col cols="12">
              <v-textarea label="Заметки" outlined v-model="notes"></v-textarea>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-row justify="center">
        <v-btn color="primary" class="ml-n2" @click="createActor">Сохранить</v-btn>
        <v-btn color="error" class="ml-6" @click="$router.push('/')">Отменить</v-btn>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import { axiosBase } from "../api/axios-base";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      statusOpt: ["On", "Off"],
      genderOpt: [
        { text: "Male", value: "M" },
        { text: "Female", value: "F" },
      ],
      imageSrc:
        "",
      audioSrc: "",
      isEditPhoto: false,
      isPlayingAudio: false,
      audioName: "",
      site_id: "",
      full_name: "",
      ru_name: "",
      eng_name: "",
      status: "",
      gender: "",
      photo: "",
      photo_thumbnail: "",
      language: "",
      dialect: "",
      country: "",
      city: "",
      flag: "",
      voice_file: "",
      bio_ru: "",
      bio_eng: "",
      upwork_url: "",
      fiverr_url: "",
      extra_url_1: "",
      extra_url_2: "",
      email: "",
      skype: "",
      phone: "",
      extra_contact: "",
      currency: "",
      price: "",
      paypal_email: "",
      extra_price: "",
      notes: "",
    };
  },
  methods: {
    ...mapActions(["GET_SELECTED_ACTOR","CREATE_ACTOR"]),
    getResult() {
      const canvas = this.$refs.clipper.clip();
      this.imageSrc = canvas.toDataURL("image/jpeg", 1);
      this.isEditPhoto = false;
    },
    previewThumbnail: function (event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        this.audioName = input.files[0].name;
        var reader = new FileReader();
        var vm = this;
        reader.onload = function (e) {
          vm.audioSrc = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    playAudio() {
      if (this.isPlayingAudio == false) {
        var snd = new Audio(this.audioSrc);
        snd.play();
        this.isPlayingAudio = true;
      } else {
        snd.pause();
      }
    },
    createActor() {
      let actor = {
        site_id: this.site_id,
        full_name: this.full_name,
        ru_name: this.ru_name,
        eng_name: this.eng_name,
        status: this.status,
        gender: this.gender,
        photo: this.imageSrc,
        language: {language: this.language},
        dialect: {dialect: this.dialect},
        country: {country: this.country},
        city: this.city,
        flag: {flag: this.flag},
        voice_file: this.audioSrc,
        bio_ru: this.bio_ru,
        bio_eng: this.bio_eng,
        upwork_url: this.upwork_url,
        fiverr_url: this.fiverr_url,
        extra_url_1: this.extra_url_1,
        extra_url_2: this.extra_url_2,
        email: this.email,
        skype: this.skype,
        phone: this.phone,
        extra_contact: this.extra_contact,
        currency: {currency: this.currency},
        price: this.price,
        paypal_email: this.paypal_email,
        extra_price: this.extra_price,
        notes: this.notes,
      }
      axiosBase.post(
        "/api/v1/actors/" , actor
      )
      .then(res => {
        console.log(res)
      })
    }
  },
   computed: {
    ...mapGetters([
      "LANGUAGE",
      "DIALECT",
      "COUNTRY",
      "CURRENCY"
    ]),
  },
  created() {
    this.$store.dispatch("GET_FILLERS");
  },

};
</script>

<style>
.my-clipper {
  width: 100%;
  max-width: 260px;
}
</style>
